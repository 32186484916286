<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="font-weight-bold">{{ $t(elementName + '.name') }}</span>
      </div>
    </b-card-header>
    <b-card-body>

      <b-row>
        <!-- Right Col: Table -->
        <b-col
          v-if="elementData && elementData.user"
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.user') }}</span>
              </th>
              <td class="pb-50">
                {{ getUserName(elementData.user) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.management_medium') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.management_medium.value }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.group') }}</span>
              </th>
              <td class="pb-50">
                ---
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.reason') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.reason.value }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.result') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.result.value }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.registry_time') }}</span>
              </th>
              <td class="pb-50">
                {{ showDateTime(elementData.registry_time) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.observations') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.observations }}
              </td>
            </tr>

          </table>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'

import { avatarText } from '@core/utils/filter'
import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
      resolveElementStatusVariant(status) {
        if (status === 0) return 'warning'
        return 'success'
      },
      resolveElementStatusMessage(status) {
        if (status === 0) return this.$t(`${this.elementName}.status.inactive`)
        return this.$t(`${this.elementName}.status.active`)
      },
    }
  },
  methods: {
    router() {
      return router
    },
    getUserName(user) {
      const middle_name = user.middle_name ? user.middle_name : ''
      const surname_2 = user.surname_2 ? user.surname_2 : ''
      return `${user.firt_name} ${middle_name} ${user.surname_1} ${surname_2}`
    },
    showDateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
  },
}
</script>

<style>

</style>
